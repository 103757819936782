<template>
  <div>
    <q-card v-if="profile">
      <q-table
        class="sticky-action full-width"
        title="Certificates"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        row-key="id"
      >
        <template #top-left>
          <span class="text-h6 q-ml-sm">Certificates</span>
        </template>
        <template #top-right>
          <q-input dense debounce="300" color="primary" v-model="filter">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-url="{ row }">
          <td>
            <a :href="row.url" target="_blank">{{ row.url }}</a>
          </td>
        </template>
      </q-table>
    </q-card>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { formatDate } from '../utils/functions'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    const filter = ref('')

    const columns = ref([
      { name: 'title', label: 'Title', align: 'left', field: 'title', sortable: true },
      { name: 'url', label: 'Url', align: 'left', field: 'url', sortable: true },
      { name: 'isForever', label: 'Forever', field: 'isForever', sortable: false, format: val => val ? 'Yes' : 'No' },
      { name: 'publisher', label: 'Publisher', field: 'publisher', sortable: true },
      { name: 'publishedAt', label: 'Published At', field: 'publishedAt', format: val => formatDate(val, 'YYYY, MMM') },
      { name: 'expiredAt', label: 'Expired At', field: 'expiredAt', format: val => formatDate(val, 'YYYY, MMM') }
    ])

    const rows = computed(() => store.state.certificates)

    return {
      profile,
      filter,
      columns,
      rows
    }
  }
}
</script>
